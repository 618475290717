<template>
  <v-container
    fluid
    py-3
    px-0
  >
    <v-form
      class="visitor-incident-form"
      lazy-validation
      @submit.prevent="onSubmit"
    >
      <v-layout v-if="settings.reporter_type == '1'">
        <v-flex
          xs11
          sm8
        >
          <v-select
            v-model="form.reporter.type"
            v-validate="'required'"
            data-vv-name="type"
            class="required"
            name="type"
            :label="t('main.views.report.form.reporter.type.label')"
            :error-messages="t(errors.first('type'))"
            :items="(!loading) ? enums.ReporterType : []"
            item-text="label"
            item-value="value"
          />
        </v-flex>
      </v-layout>
      <v-layout v-if="form.reporter.type == 'other'">
        <v-flex
          xs12
          sm8
        >
          <v-textarea
            v-model="form.reporter.description"
            v-validate="'required|max:1000'"
            data-vv-name="description"
            class="required"
            name="description"
            :label="t('main.views.report.form.reporter.typeother.label')"
            :error-messages="t(errors.first('description'))"
          />
        </v-flex>
      </v-layout>
      <v-layout v-if="settings.incident_date == '1'">
        <v-flex
          xs8
          sm5
        >
          <DatePicker
            v-model="form.incident.date"
            v-validate="'required'"
            :value="form.incident.date"
            data-vv-name="date"
            name="date"
            class="required"
            :label="t('main.views.report.form.incident.date.label')"
            :error-messages="t(errors.first('date'))"
            :error="errors.has('date')"
            @dateChanged="setDate"
          />
        </v-flex>
      </v-layout>
      <v-layout v-if="settings.incident_location == '1'">
        <v-flex
          xs12
          sm8
        >
          <vuetify-google-autocomplete
            id="location"
            ref="location"
            class="required"
            v-model="form.incident.locationDisplay"
            v-validate="'required'"
            :error-messages="t(errors.collect('location'))"
            :error="errors.has('location')"
            data-vv-name="location"
            name="location"
            append-icon="search"
            :clearable="true"
            :enable-geolocation="false"
            :label="t('main.views.report.form.incident.location.label')"
            prepend-icon=""
            types="address"
            @placechanged="getAddressData"
            @inputChange="() => {}"
          />
        </v-flex>
      </v-layout>
      <v-layout v-if="settings.incident_summary == '1'">
        <v-flex
          xs12
          sm8
        >
          <v-textarea
            v-model="form.incident.summary"
            v-validate="'required|max:1000'"
            data-vv-name="summary"
            class="required"
            name="summary"
            :label="t('main.views.report.form.incident.summary.label')"
            :hint="t('main.views.report.form.incident.summary.help')"
            :error-messages="t(errors.first('summary'))"
            persistent-hint
          />
        </v-flex>
      </v-layout>
      <v-layout v-if="settings.incident_files == '1'">
        <v-flex
          xs12
          sm8
        >
          <v-select-file
            v-model="form.files"
            v-validate="'check_files_size:20000|ext:jpg,jpeg,png,gif,bmp,pdf,doc,docx,rtf,txt,odt,sxw,fodt,uot,uof,xls,xlsx'"
            name="files"
            data-vv-name="files"
            :error-messages="t(errors.first('files'))"
            :label="t('main.views.report.form.upload.label')"
            :hint="t('main.views.report.form.upload.help')"
            :button-label="t('main.views.report.form.upload.button')"
            persistent-hint
          />
        </v-flex>
      </v-layout>
      <v-layout v-if="settings.reporter_email == '1'">
        <v-flex
          xs12
          sm8
        >
          <v-text-field
            v-model="form.reporter.email"
            v-validate="'required|email'"
            data-vv-name="email"
            class="required"
            name="email"
            :label="t('main.views.report.form.reporter.email.label')"
            :error-messages="t(errors.first('email'))"
            :hint="t('main.views.report.form.reporter.email.help')"
            persistent-hint
          />
        </v-flex>
      </v-layout>
      <v-layout v-if="settings.reporter_name == '1'">
        <v-flex
          xs12
          sm8
        >
          <v-text-field
            v-model="form.reporter.name"
            name="name"
            :label="t('main.views.report.form.reporter.name.label')"
            :error-messages="t(errors.first('name'))"
          />
        </v-flex>
      </v-layout>
      <v-layout v-if="settings.reporter_contactPolice == '1'">
        <v-flex
          xs12
          sm8
        >
          <v-select
            v-model="form.reporter.contactPolice"
            v-validate="'required'"
            data-vv-name="contactPolice"
            class="required"
            name="contactPolice"
            :label="t('main.views.report.form.reporter.contactpolice.label')"
            :hint="t('main.views.report.form.reporter.contactpolice.help')"
            :error-messages="t(errors.first('contactPolice'))"
            :items="(!loading) ? enums.ContactPoliceType : []"
            item-text="label"
            item-value="value"
            presistent-hint
          />
        </v-flex>
      </v-layout>
      <v-layout v-if="settings.custom_text == '1'">
        <v-flex
          xs12
          sm8
        >
          <v-text-field
            v-model="form.custom.text"
            v-validate="customFieldsRules"
            name="custom_text"
            :label="t('main.views.report.form.customtext.label')"
            :error-messages="t(errors.first('custom_text'))"
          />
        </v-flex>
      </v-layout>
      <v-layout
        v-if="settings.custom_checkbox == '1'"
        class="align-center"
      >
        <v-flex shrink>
          <v-checkbox
            v-model="form.custom.checkbox"
            v-validate="customFieldsRules"
            data-vv-name="custom_checkbox"
            name="custom_checkbox"
            :error="errors.has('custom_checkbox')"
          />
        </v-flex>
        <v-flex grow>
          {{ t('main.views.report.form.customcheckbox.label', {'ngo' : 'common.general.ngo'}) }}
          <div
            :v-if="errors.has('custom_checkbox')"
            class="v-messages error--text"
          >
            {{ errors.first('custom_checkbox') }}
          </div>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="settings.recaptcha == '1'"
        class="dont-count"
      >
        <v-flex
          xs12
          sm8
        >
          <vue-recaptcha
            :sitekey="recaptchaKey"
            @verify="onCaptcha"
          />
          <div class="error--text">
            <input
              v-model="form.reCaptcha"
              v-validate="'required'"
              type="hidden"
              name="reCaptcha"
              data-vv-name="reCaptcha"
            >
            <div
              :v-if="errors.has('reCaptcha')"
              class="v-messages error--text"
            >
              {{ t(errors.first('reCaptcha')) }}
            </div>
          </div>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="settings.reporter_agreement == '1'"
        class="dont-count"
      >
        <v-flex shrink>
          <v-checkbox
            v-model="form.reporter.agreement"
            v-validate="'required:true'"
            data-vv-name="agreement"
            name="agreement"
            :error="errors.has('agreement')"
          />
        </v-flex>
        <v-flex xs12>
          {{ t('main.views.report.form.reporter.agreement.label', {'ngo' : 'common.general.ngo'}) }}
          <div
            :v-if="errors.has('agreement')"
            class="v-messages error--text"
          >
            {{ errors.first('agreement') }}
          </div>
        </v-flex>
      </v-layout>
      <v-layout class="dont-count">
        <v-flex
          xs12
          sm8
          class="py-3"
        >
          <v-btn
            large
            class="incident-submit"
            type="submit"
            primary
          >
            {{ t('main.views.report.form.submit') }}
          </v-btn>
        </v-flex>
      </v-layout>
      <v-layout class="dont-count">
        <v-flex class="form-legend">
          <b>*</b> {{ t('main.components.form.required') }}
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import { Validator } from 'vee-validate';
import Vue from 'vue';
import VueRecaptcha from 'vue-recaptcha';
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import { mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import SelectFile from '../../../common/components/form/SelectFile';
import config from '../../../common/lib/Config';
import { statusHandler } from '../../../common/lib/Forms';
import check_files_size from '../../../common/validators/check_files_size';
import DatePicker from '../../../common/components/form/DatePicker';

Validator.extend('check_files_size', check_files_size);

Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: config('keys.GOOGLE_MAPS_KEY'),
});

export default {
  name: 'VisitorIncidentForm',
  components: {
    DatePicker,
    'v-select-file': SelectFile,
    VueRecaptcha,
  },
  data() {
    return {
      form: cloneDeep(this.$store.getters['incidentForm/form']),
      recaptchaKey: config('keys.GOOGLE_RECAPTCHA_KEY'),
    };
  },
  computed: {
    ...mapGetters({
      formStatus: 'incidentForm/formStatus',
      enums: 'common/enums',
      info: 'common/info',
      settings: 'common/settings',
      loading: 'loading',
    }),
    customFieldsRules() {
      if (this.info) {
        if (this.info.customFieldsRequired) {
          return 'required:true';
        }
      }
      return '';
    },

  },
  watch: {
    formStatus: {
      deep: true,
      handler: statusHandler,
    },
  },
  methods: {
    getAddressData(addressData, placeResultData, id) {
      const fallbackValue = this.$refs.location.$el.getElementsByTagName('input')[0].value || ''; // hacky
      this.form.incident.locationDisplay = get(placeResultData, 'adr_address', fallbackValue);
      this.form.incident.location = addressData || fallbackValue;
    },
    setDate(date) {
      this.form.incident.date = date;
    },
    onCaptcha(payload) {
      this.form.reCaptcha = payload;
    },
    onSubmit() {
      this.$validator.validateAll()
        .then((response) => {
          if (response) {
            this.$store.dispatch('incidentForm/send', this.form);
          } else {
            this.$store.dispatch('message', { body: 'validation.error', type: 'error' });
          }
        })
        .catch((error) => {
          this.$store.dispatch('message', { body: 'validation.error', type: 'error' });
        });
    },
    mock() {
      this.form = {
        reporter: {
          type: 'victim',
          name: 'Some name',
          email: 'sample@ema.il',
          agreement: true,
          contactPolice: 'yes',
          description: 'Sample description',
        },
        incident: {
          location: 'My Location',
          date: '2018-01-02',
          locationDisplay: 'My location',
          summary: 'Sample summary',
        },
        files: null,
        reCaptcha: null,
      };
    },

  },
};
</script>
