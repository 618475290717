import map from 'lodash/map';
import ucfirst from 'lodash/startCase';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import Client from '../../api/Client';

const api = new Client();

export default class DefaultCommonStore {
  state() {
    return {
      user: null,
      currentLocale: null,
      locale: null,
      keys: null,
      enums: null,
      settings: [],
      printEvent: null,
      info: [],
    };
  }

  getters() {
    return {
      info(state) {
        return state.info;
      },
      user(state) {
        return state.user;
      },
      locale(state) {
        return state.locale;
      },
      currentLocale(state) {
        return state.currentLocale;
      },
      keys(state) {
        return state.keys;
      },
      enums(state) {
        return state.enums;
      },
      settings(state) {
        return state.settings;
      },
      getPrintEvent(state) {
        return state.printEvent;
      },
    };
  }

  mutations() {
    return {
      setLocale(state, payload) {
        state.locale = map(payload, (item) => ({ lang: item, label: ucfirst(trans(`main.components.header.locales.${item}`)) }));
      },
      setCurrentLocale(state, payload) {
        state.currentLocale = payload;
      },
      setInfo(state, payload) {
        state.info = payload;
      },
      setKeys(state, payload) {
        state.keys = payload;
      },
      setSettings(state, payload) {
        const result = [];
        forEach(payload, (item, key) => {
          if (item == '1' || item == '0') {
            result[key] = (item == '1');
          } else {
            result[key] = item;
          }
        });
        state.settings = result;
      },
      setEnums(state, payload) {
        const translationsMap = {
          FileGroup: '',
          IncidentCategory: 'common.options.incident.category.',
          IncidentForm: 'common.options.incident.form.',
          IncidentPerpetrator: 'admin.common.options.perpetrator.type.',
          IncidentStatus: 'common.options.status.',
          IncidentTypeGroup: '',
          ContactPoliceType: 'admin.common.options.reporter.contactpolice.',
          ReporterType: 'main.views.report.form.reporter.type.options.',
          SettingsGroup: '',
          UserType: 'common.options.role.',
          PhysicalType: 'common.options.incident.physical.',
          VictimGender: 'admin.common.options.victim.gender.',
          ExportColumns: 'admin.views.translations.importexport.form.columns.',
        };

        const result = {};
        forEach(payload, (items, key) => {
          const itemsArray = [];
          forEach(items, (item, id) => {
            let val = item;
            if (key === 'UserType') {
              val = id + 1;
            }
            if (key === 'ExportColumns') {
              val = id;
            }
            itemsArray.push({ value: val, name: item, label: trans(`${translationsMap[key]}${item}`) });
          });
          result[key] = itemsArray;
        });

        state.enums = result;
      },
      setPrintEvent(state) {
        state.printEvent = new Date().getTime();
      },
    };
  }

  actions(reloadTranslations) {
    return {
      printEvent(context) {
        context.commit('setPrintEvent');
      },
      changeLocale(context, payload) {
        const data = {
          locale: payload,
        };


        context.dispatch('loading', true, { root: true });
        api.changeLocale(data).then((response) => {
          context.commit('setCurrentLocale', get(response, 'data.currentLocale', null));
          // setTimeout(() => {
          // },500);
          if (reloadTranslations(response.data.translations)) {
            context.commit('setEnums', get(response, 'data.enums', []));
            context.dispatch('loading', false, { root: true });
          }
        }).catch((error) => {
          context.dispatch('message', {
            body: trans('common.error.unknown'),
            type: 'error',
            error,
          }, { root: true });
          context.dispatch('loading', false, { root: true });
        });
      },
      async init(context) {
        const data = {
          locale: context.state.currentLocale,
        };

        context.dispatch('loading', true, { root: true });
        await api.getInitialData(data).then((response) => {
          context.commit('setLocale', response.data.locales);
          context.commit('setCurrentLocale', response.data.currentLocale);
          context.commit('setKeys', response.data.keys);
          context.commit('setInfo', response.data.info);
          context.commit('setEnums', response.data.enums);
          context.commit('setSettings', response.data.settings);
          context.dispatch('loading', false, { root: true });
        }).catch((error) => {
          context.dispatch('loading', false, { root: true });
          context.dispatch('message', {
            body: trans('common.error.unknown'),
            type: 'error',
            error,
          }, { root: true });
        });
      },
    };
  }
}
